<template>
  <button :class="classes" :type="type" @click="handleClick">
    <slot />
  </button>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

export type ButtonVariant = 'primary' | 'secondary';

const NAME = 'Button';

export default Vue.extend({
  name: NAME,

  props: {
    type: {
      type: String,
      default: 'button'
    },

    variant: {
      type: String as PropType<ButtonVariant>,
      default: undefined
    },

    block: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes(): { [name: string]: boolean } {
      return {
        [NAME]: true,
        [`-${this.variant}`]: !!this.variant,
        '-block': !!this.block
      };
    }
  },

  methods: {
    handleClick(e: MouseEvent): void {
      this.$emit('click', e);
    }
  }
});
</script>

<style>
.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  padding: 0 20px;
  border: 1px solid var(--mono-divider);
  border-radius: 4px;
  background: var(--mono-white);
  color: var(--mono-primary);
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
}

.Button:hover {
  opacity: 0.7;
}

.Button:disabled {
  opacity: 0.4;
  pointer-events: none;
}

.Button.-primary {
  border-radius: 100rem;
  border-color: var(--primary);
  background: var(--primary);
  color: var(--mono-white);
}

.Button.-secondary {
  border-radius: 100rem;
  border-color: var(--mono-background);
  background: var(--mono-background);
}

.Button.-block {
  display: flex;
  width: 100%;
}
</style>
