<template>
  <div>
    <nuxt />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import CommonHeader from '~/components/common/Header.vue';
import CommonFooter from '~/components/common/Footer.vue';

export default Vue.extend({
});
</script>
