<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

const NAME = 'ShopList';

export default Vue.extend({
  props: {
    wrap: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      return {
        [NAME]: true,
        '-wrap': !!this.wrap
      };
    }
  }
});
</script>

<style>
.ShopList {
  display: flex;
  flex-wrap: nowrap;
  padding: 16px;
  overflow: scroll;
}

.ShopList > * {
  margin-top: 32px;
}

.ShopList.-wrap {
  flex-wrap: wrap;
  justify-content: space-between;
}

.ShopList:not(.-wrap) > * {
  margin-right: 16px;
}

.ShopList:not(.-wrap) > *:last-child {
  margin-right: 0;
}

@media (max-width: 768px) {
  .ShopList.-wrap {
    justify-content: center;
  }
}
</style>
