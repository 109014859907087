<template>
  <div class="seeyouagain">
    <div class="seeyouagain__inner">
      <header class="MainHeader">
      <img src="https://storage.googleapis.com/chompy-jp-static-files/seeyouagain/shop_hero.png" alt="ショップヒーロー画像">
    </header>
    <main class="MainLayout">
      <div class="MainLayout__ShopProfile">
        <h2>{{ partnerStat?.name }}</h2>
        <p>今までの注文をまとめました</p>
      </div>
      <div class="MainLayout__Contents">
        <div class="MainLayout__Contents__Detail">
          <div class="MainLayout__Contents__Detail__Column">
            <img src="https://storage.googleapis.com/chompy-jp-static-files/seeyouagain/crown.png" width="40" height="40" alt="👑" />
            <h3>売れた商品（上位20個）</h3>
          </div>
          <div class="MainLayout__Contents__Detail__Items">
            <div class="MainLayout__Contents__Detail__Item" v-for="(item, index) in partnerStat?.item_ranking" :key="index">
              <img :src="item.image" :alt="item.name" height="40" />
              <div>
                <h4>{{ item.name }}</h4>
                <span>{{ item.completed_order_count }}個販売</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="MainLayout__Contents">
        <div class="MainLayout__Contents__Detail">
          <div class="MainLayout__Contents__Detail__Column">
            <img src="https://storage.googleapis.com/chompy-jp-static-files/seeyouagain/blush.png" width="40" height="40" alt="☺️" />
            <h3>常連さんランキング</h3>
          </div>
          <div class="MainLayout__Contents__Detail__Items">
            <div class="MainLayout__Contents__Detail__Item" v-for="(user, index) in partnerStat?.repeater_ranking" :key="index">
              <img :src="user.image" width="40" height="40" alt="ユーザーアイコン" style="border-radius:50%;">
              <div>
                <h4>{{ user.name }}</h4>
                <span>{{ user.completed_order_count }}回注文</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="MainLayout__Contents" v-if="partnerStat?.reviews.length">
        <div class="MainLayout__Contents__Detail">
          <div class="MainLayout__Contents__Detail__Column">
            <img src="https://storage.googleapis.com/chompy-jp-static-files/seeyouagain/smiling_face_with_3_hearts.png" width="40" height="40" alt="にこにこ" />
            <h3>もらったレビュー</h3>
          </div>
          <div class="MainLayout__Contents__Detail__Column">
            <ul class="MainLayout__Contents__ReviewLists">
              <li v-for="(review, index) in partnerStat?.reviews" :key="index">{{ review }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="MainLayout__Annotation">
        <p>※2023年5月13日頃までの情報です</p>
      </div>
      <div class="MainLayout__LastMessage">
        <div class="MainLayout__LastMessage__Text">
          <p>SEE YOU AGAIN</p>
        </div>
      </div>
    </main>
  </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { shopMapper, shopModule } from '~/store/modules/shop';
export default Vue.extend({
  computed: {
    ...shopMapper.mapGetters(['partnerStat']),
  },
  async mounted() {
    const shopStatState = shopModule.context(this.$store);
    const { id } = this.$route.params;
    await shopStatState.actions.getPartnerStat(id);
  },
});
</script>


<style scoped>
.seeyouagain {
  background-color: rgba(239, 239, 239, 0.3);
  min-height: 100vh;
}

.seeyouagain__inner {
  max-width: 560px;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
}

.MainHeader {
  background-color: #fff;
}
.MainLayout {
  /* Brand/Primary-UltraLight */
  background-position: right top;
  background-image: url("https://storage.googleapis.com/chompy-jp-static-files/seeyouagain/shop_bg_top.png");
  background-size: 66%;
  background-repeat: no-repeat;
  border-top: 1px solid rgba(255, 128, 128, 0.1);
  min-height: 200px;
}
.MainLayout__ShopProfile {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  margin-top: 24px;
  margin-left: 20px;
}
.MainLayout__ShopProfile > h2 {
  font-size: var(--fz-18);
}
.MainLayout__ShopProfile > p {
  font-size: var(--fz-14);
  color: #888888;
}
.MainLayout__Contents {
  margin: 20px;
  display: flex;
  gap: 16px 20px;
}

.MainLayout__Contents__Detail {
  flex: 1;
  background-color: var(--mono-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 8px rgba(12, 35, 64, 0.1);
  border-radius: 4px;
  padding: 20px 16px 16px;
  gap: 12px;
}
.MainLayout__Contents__Detail__Column {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  width: 100%;
  justify-content: center;
}
.MainLayout__Contents__Detail__Items {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 12px;
  width: 100%;
}
.MainLayout__Contents__Detail__Item {
  display: flex;
  gap: 8px;
  background: rgba(239, 239, 239, 0.3);
  border-radius: 8px;
  padding: 12px;
}
.MainLayout__Contents__Detail__Item span {
  font-weight: 700;
  color: var(--primary-text);
}


.MainLayout__Contents__ReviewLists {
  list-style-type: none;
  display: grid;
  margin: 0;
  padding: 0;
  gap: 8px;
  width: 100%;
  text-align: left;
}
.MainLayout__Contents__ReviewLists li {
  background: rgba(239, 239, 239, 0.3);
  border-radius: 8px;
  padding: 12px;
  font-weight: 700;
  font-size: 0.9rem;
  width: 100%;
}

.MainLayout__Annotation {
  /* Text Extra Small */

  font-family: 'SF Hiragino';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  /* identical to box height, or 17px */

  text-align: center;

  /* Mono/Secondary */

  color: #888888;
}

.MainLayout__LastMessage {
  margin-top: 34px;
  line-height: 140%;
  text-align: center;
  font-weight: bold;
  padding-bottom: 24px;
}

.MainLayout__LastMessage a {
  color: var(--mono-primary);
  font-weight: normal;
}

.MainLayout__LastMessage__Text {
  overflow: hidden;
  position: relative;
  height: 100px;
}
.MainLayout__LastMessage__Text p {
  font-size: clamp(3rem, 5vw + 1.5rem, 5.333rem);
  width: 700px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--mono-background);
}
</style>
