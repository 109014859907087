<template>
  <img :class="classes" :src="src" :alt="alt" loading="lazy" decoding="async" />
</template>

<script lang="ts">
import Vue from 'vue';

const NAME = 'Image';

export default Vue.extend({
  // HACK: 予約語 `image` を回避
  //       https://github.com/vuejs/vue/blob/dev/src/platforms/weex/util/element.js#L9
  name: `Ui${NAME}`,

  props: {
    src: {
      type: String,
      required: true
    },

    alt: {
      type: String,
      default: ''
    },

    rounded: {
      type: Boolean,
      default: false
    },

    circle: {
      type: Boolean,
      default: false
    },

    fit: {
      type: String as () => 'contain' | 'cover',
      default: null
    }
  },

  computed: {
    classes(): { [name: string]: boolean } {
      return {
        [NAME]: true,
        '-rounded': this.rounded,
        '-circle': this.circle,
        '-contain': this.fit === 'contain',
        '-cover': this.fit === 'cover'
      };
    }
  }
});
</script>

<style>
.Image {
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
}

.Image.-rounded {
  border-radius: 4px;
}

.Image.-circle {
  border-radius: 50%;
}

.Image.-contain {
  object-fit: contain;
}

.Image.-cover {
  object-fit: cover;
}
</style>
