<template>
  <div class="TitleContainer">
    <slot />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend();
</script>

<style>
.TitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
