<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

const NAME = 'MenuList';

export default Vue.extend({
  props: {
    column: {
      type: Number,
      default: 3
    }
  },
  computed: {
    classes() {
      return {
        [NAME]: true,
        [`-column-${this.column}`]: this.column
      };
    }
  }
});
</script>

<style>
.MenuList {
  display: grid;
  grid-gap: 16px;
  margin: 16px 0 0;
}

.MenuList.-column-3 {
  grid-template-columns: repeat(auto-fill, minmax(calc((768px / 3) - 16px), 1fr));
}

.MenuList.-column-4 {
  grid-template-columns: repeat(auto-fill, minmax(calc((768px / 4) - 16px), 1fr));
}

.MenuList.-column-5 {
  grid-template-columns: repeat(auto-fill, minmax(calc((768px / 5) - 16px), 1fr));
}
</style>
