<template>
  <div class="Container">
    <slot />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend();
</script>

<style>
.Container {
  max-width: 1140px;
  margin: auto;
}
</style>
