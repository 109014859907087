<template>
  <div class="seeyouagain">
    <div class="seeyouagain__inner">
      <header class="MainHeader">
        <img
          src="https://storage.googleapis.com/chompy-jp-static-files/seeyouagain/driver_hero.png"
          alt="クルーヒーロー画像"
        />
      </header>
      <main class="MainLayout">
        <div class="MainLayout__UserProfile">
          <img
            src="https://storage.googleapis.com/chompy-jp-static-files/seeyouagain/user_icon.png"
            width="40"
            height="40"
            alt="ユーザーアイコン"
          />
          <h2>{{ driverStat?.name }}さん</h2>
        </div>
        <div class="MainLayout__Contents">
          <div class="MainLayout__Contents__Detail">
            <img
              src="https://storage.googleapis.com/chompy-jp-static-files/seeyouagain/hamburger.png"
              width="40"
              height="40"
              alt="🍔"
            />
            <h3>総配達数</h3>
            <p class="MainLayout__Contents__Detail__Contents">{{ driverStat?.delivery_count  }}<span> 件</span></p>
          </div>
          <div class="MainLayout__Contents__Detail">
            <img
              src="https://storage.googleapis.com/chompy-jp-static-files/seeyouagain/crown.png"
              width="40"
              height="40"
              alt="👑"
            />
            <h3>配達ランキング</h3>
            <p class="MainLayout__Contents__Detail__Contents">
              {{ driverStat?.driver_rank }}<br />
              <span>/上位500人中</span>
            </p>
          </div>
        </div>
        <div class="MainLayout__Contents" v-if="driverStat?.challenge_entry_count">
          <div class="MainLayout__Contents__Detail">
            <div class="MainLayout__Contents__Detail__Column">
              <img
                src="https://storage.googleapis.com/chompy-jp-static-files/seeyouagain/muscle.png"
                width="40"
                height="40"
                alt="💪"
              />
              <h3>チャレンジ</h3>
            </div>
            <div class="MainLayout__Contents__Detail__Column">
              <div class="MainLayout__Contents__Detail__Challenge">
                <h4>参加数</h4>
                <p class="MainLayout__Contents__Detail__Contents">{{ driverStat?.challenge_entry_count }}<span> 件</span></p>
              </div>
              <div class="MainLayout__Contents__Detail__Challenge">
                <h4>成功数</h4>
                <p class="MainLayout__Contents__Detail__Contents">{{ driverStat?.challenge_success_count  }}<span> 件</span></p>
              </div>
              <div class="MainLayout__Contents__Detail__Challenge">
                <h4>成功率</h4>
                <p class="MainLayout__Contents__Detail__Contents">{{ driverStat?.challenge_success_ratio }}<span> %</span></p>
              </div>
            </div>
          </div>
        </div>
        <div class="MainLayout__Contents">
          <div class="MainLayout__Contents__Detail">
            <img
              src="https://storage.googleapis.com/chompy-jp-static-files/seeyouagain/cowboy.png"
              width="40"
              height="40"
              alt="🤠"
            />
            <h3>最高ランク</h3>
            <p class="MainLayout__Contents__Detail__Contents RankName">
              {{ driverStat?.highest_rank  }}<span></span><br />
              <span class="sub">{{ driverStat?.highest_rank_week }}</span>
            </p>
          </div>
          <div class="MainLayout__Contents__Detail">
            <img
              src="https://storage.googleapis.com/chompy-jp-static-files/seeyouagain/sun.png"
              width="40"
              height="40"
              alt="☀️"
            />
            <h3>総走行距離</h3>

            <p class="MainLayout__Contents__Detail__Contents">
              {{ driverStat?.sum_delivery_distance_km }}<span> km</span><br />
              <span class="sub">🏃‍♀️フルマラソン{{ driverStat?.full_marathon_count }}回分</span>
            </p>
          </div>
        </div>
        <div class="MainLayout__Contents" v-if="driverStat?.reviews.length">
          <div class="MainLayout__Contents__Detail">
            <div class="MainLayout__Contents__Detail__Column">
              <img
                src="https://storage.googleapis.com/chompy-jp-static-files/seeyouagain/smiling_face_with_3_hearts.png"
                width="40"
                height="40"
                alt="にこにこ"
              />
              <h3>もらったレビュー</h3>
            </div>
            <div class="MainLayout__Contents__Detail__Column">
              <ul class="MainLayout__Contents__ReviewLists">
                <li v-for="(review, index) in driverStat?.reviews" :key="index">{{ review }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="MainLayout__Annotation">
          <p>※2023年5月13日頃までの情報です</p>
        </div>
        <div class="MainLayout__LastMessage">
          <p>
            まいにちの暮らしを、おいしく笑顔に。<br />
            <br />
            いままで Chompy を<br />
            ご利用いただきありがとうございました。<br />
            <br />
            飲食店向けアプリでお会いしましょう✨️<br />
            <br />
            <a href="https://blog.chompy.jp/lp">飲食店向けアプリとは？</a>
          </p>
          <div class="MainLayout__LastMessage__Text">
            <p>SEE YOU AGAIN</p>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { driverStatMapper, driverStatModule } from '~/store/modules/driver';
export default Vue.extend({
  computed: {
    ...driverStatMapper.mapGetters(['driverStat']),
  },
  async mounted() {
    const driverStatState = driverStatModule.context(this.$store);
    const { id } = this.$route.params;
    await driverStatState.actions.getDriverStat(id);
    console.log("driverStat", this.driverStat);
  },
});
</script>

<style scoped>
.seeyouagain {
  background-color: rgba(12, 35, 64, 0.1);
  min-height: 100vh;
}

.seeyouagain__inner {
  max-width: 560px;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  background-color: rgba(12, 35, 64, 0.1);
}

.MainHeader {
  background-color: #fff;
}
.MainLayout {
  /* Brand/Primary-UltraLight */
  background-position: right top;
  background-image: url('https://storage.googleapis.com/chompy-jp-static-files/seeyouagain/driver_bg_top.png');
  background-size: 66%;
  background-repeat: no-repeat;
  border-top: 1px solid #dadada;
  min-height: 200px;
}
.MainLayout__UserProfile {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  margin-top: 24px;
  margin-left: 20px;
}
.MainLayout__UserProfile > h2 {
  font-size: var(--fz-18);
}
.MainLayout__Contents {
  margin: 20px;
  display: flex;
  gap: 16px;
}

.MainLayout__Contents__Detail {
  flex: 1;
  background-color: var(--mono-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 8px rgba(12, 35, 64, 0.1);
  border-radius: 4px;
  padding: 20px 16px 16px;
  gap: 12px;
}
.MainLayout__Contents__Detail__Column {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-top: 8px;
  width: 100%;
  text-align: center;
  @media screen and (max-width: 450px) {
    gap: 8px;
  }
}
.MainLayout__Contents__Detail__Challenge {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.MainLayout__Contents__Detail__Contents {
  font-size: clamp(2.222rem, 3vw + 1.5rem, 3.111rem);
  line-height: clamp(1.333rem, 3vw + 0.5rem, 2rem);
  color: var(--secondary);
  font-weight: 700;
  margin-bottom: -4px;
  text-align: center;
}
.MainLayout__Contents__Detail__Contents.RankName {
  font-size: clamp(1.778rem, 2vw + 1rem, 2rem);
}
.MainLayout__Contents__Detail p {
  font-family: 'DIN Alternate', 'Oswald', sans-serif;
}
.MainLayout__Contents__Detail p > span {
  font-size: clamp(0.889rem, 1vw + 0.5rem, 1.111rem);
  font-weight: 400;
}

.MainLayout__Contents__Detail p > span.sub {
  color: var(--mono-secondary);
  display: inline-block;
}

.MainLayout__Contents__Detail__Challenge > h4 {
  font-size: clamp(0.611rem, 1vw + 0.25rem, 0.778rem);
  margin-bottom: 8px;
}

.MainLayout__Contents__ReviewLists {
  list-style-type: none;
  display: grid;
  margin: 0;
  padding: 0;
  gap: 8px;
  width: 100%;
  text-align: left;
}
.MainLayout__Contents__ReviewLists li {
  background: rgba(12, 35, 64, 0.1);
  border-radius: 8px;
  padding: 12px;
  font-weight: 700;
  font-size: 0.9rem;
}

.MainLayout__Annotation {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: var(--mono-secondary);
}

.MainLayout__LastMessage {
  margin-top: 34px;
  line-height: 140%;
  text-align: center;
  font-weight: bold;
  padding-bottom: 24px;
}

.MainLayout__LastMessage a {
  color: var(--mono-primary);
  font-weight: normal;
}

.MainLayout__LastMessage__Text {
  overflow: hidden;
  position: relative;
  height: 100px;
}
.MainLayout__LastMessage__Text p {
  font-size: clamp(3rem, 5vw + 1.5rem, 5.333rem);
  width: 700px;
  text-align: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--secondary);
  opacity: 0.2;
}
</style>
