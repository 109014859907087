<template>
  <div>
    <common-header />
    <main>
      <common-container class="u-mb48">
        <ui-breadcrumbs :items="breadcrumbs" class="u-ml16" />
        <title-container v-if="hasAreaShops" class="u-ml16 u-mt48">
          <h2>{{ this.$route.query.city }}のお店</h2>
        </title-container>
        <shop-list v-if="hasAreaShops" wrap>
          <shop-item :shop="shop" v-for="shop in areaShops" :key="shop.id" />
        </shop-list>
        <infinite-loading @infinite="fetchData" spinner="bubbles">
          <div slot="no-more" />
          <div slot="no-results" />
        </infinite-loading>
      </common-container>
    </main>
    <common-footer />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import InfiniteLoading, { StateChanger } from 'vue-infinite-loading';
import { shopsModule, shopsMapper } from '~/store/modules/shops';
import CommonHeader from '~/components/common/Header.vue';
import CommonFooter from '~/components/common/Footer.vue';
import CommonContainer from '~/components/common/Container.vue';
import TitleContainer from '~/components/common/TitleContainer.vue';
import UiBreadcrumbs, { BreadcrumbItem } from '~/components/ui/Breadcrumbs.vue';
import ShopList from '~/components/shop/List.vue';
import ShopItem from '~/components/shop/Item.vue';
import { createMetaInfo } from '~/util/meta';

export default Vue.extend({
  components: {
    CommonHeader,
    CommonFooter,
    CommonContainer,
    TitleContainer,
    UiBreadcrumbs,
    ShopList,
    ShopItem,
    InfiniteLoading
  },
  head() {
    const { prefecture, city } = this.$route.query;

    return createMetaInfo({
      title: `${city}のお店`,
      pathname: encodeURIComponent(`/shops?prefecture=${prefecture}&city=${city}`)
    });
  },
  computed: {
    ...shopsMapper.mapGetters(['areaShops', 'hasNext', 'page']),
    breadcrumbs(): BreadcrumbItem[] {
      const { prefecture, city } = this.$route.query;

      return [
        {
          name: 'ホーム',
          path: '/'
        },
        {
          name: `${city}のお店`,
          path: encodeURIComponent(`/shops?prefecture=${prefecture}&city=${city}`)
        }
      ];
    },
    hasAreaShops(): boolean {
      return this.areaShops.length !== 0;
    },
  },
  methods: {
    async fetchData(state: StateChanger) {
      if (!this.hasNext) {
        state.complete();
        return;
      }

      const shopsState = shopsModule.context(this.$store);
      const { prefecture, city } = this.$route.query;

      if (!prefecture || Array.isArray(prefecture)) {
        return;
      }

      if (!city || Array.isArray(city)) {
        return;
      }

      await shopsState.actions.listAreaShops({
        prefecture,
        city,
        page: this.page,
        limit: 100
      });
      state.loaded();
    }
  },
  watch: {
    '$route.query': '$fetch'
  }
});
</script>
