<template>
  <div>
    <div :class="classes" @click="openModal" tabindex="0">
      <div class="MenuItemComment" v-if="hasComment">
        <ui-speech-bubble class="MenuItemComment__Body u-mb8 u-taC">
          {{ menuItem.shop_comment.description }}
        </ui-speech-bubble>
        <ui-image class="MenuItemComment__Avatar" circle fit="cover" :src="shop.icon" :alt="menuItem.name" />
      </div>
      <ui-image class="MenuItemThumbnail u-mb8" rounded fit="cover" :src="menuItem.image" :alt="menuItem.name" />
      <p class="MenuItem__Name u-ellipsis">{{ menuItem.name }}</p>
      <p class="MenuItem__Price u-ellipsis">¥{{ menuItem.price }}</p>
    </div>
    <ui-modal class="MenuItemModal" v-if="open" :close="closeModal" large>
      <ui-image class="MenuItemModal__Image" :src="menuItem.image" :alt="menuItem.name" />
      <div class="MenuItemModal__Body">
        <h2 class="u-fz24">{{ menuItem.name }}</h2>
        <p class="u-fz14 u-mt16">{{ menuItem.description }}</p>
        <div v-if="hasComment" class="MenuItemModal__Comment u-mt32">
          <ui-image class="MenuItemModal__Avatar u-mr16" circle fit="cover" :src="shop.icon" :alt="shop.owner_name" />
          <ui-speech-bubble arrow="left">{{ menuItem.shop_comment.description }}</ui-speech-bubble>
        </div>
      </div>
      <!-- TODO: adjust link いれる <a class="MenuItemModal__FooterDesktop u-forDesktop" href="">アプリからご注文いただけます</a> -->
      <a class="MenuItemModal__FooterMobile u-forMobile" :href="shop.dynamic_link">
        <ui-button class="MenuItemModal__FooterMobileButton" variant="primary">Chompyアプリで注文する</ui-button>
      </a>
    </ui-modal>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import UiImage from '~/components/ui/Image.vue';
import UiSpeechBubble from '~/components/ui/SpeechBubble.vue';
import UiModal from '~/components/ui/Modal.vue';
import UiButton from '~/components/ui/Button.vue';
import { Shop, MenuItem } from '~/types/api/shop';

const NAME = 'MenuItem';

export default Vue.extend({
  components: {
    UiImage,
    UiSpeechBubble,
    UiModal,
    UiButton
  },
  props: {
    hasComment: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    shop: {
      type: Object as () => PropType<Shop>,
      required: true
    },
    menuItem: {
      type: Object as () => PropType<MenuItem>,
      required: true
    }
  },
  data() {
    return {
      open: false
    };
  },
  computed: {
    classes(): { [name: string]: boolean } {
      return {
        [NAME]: true,
        '-hasComment': !!this.hasComment,
        '-large': !!this.large
      };
    }
  },
  methods: {
    openModal() {
      this.open = true;
    },
    closeModal() {
      this.open = false;
    }
  }
});
</script>

<style>
.MenuItem {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;
}

.MenuItemComment {
  position: relative;
}

.MenuItemComment__Body {
  height: 64px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MenuItemComment__Avatar {
  position: absolute;
  top: 52px;
  left: calc(50% - 16px);
  width: 32px;
  height: 32px;
  border: solid 2px var(--mono-background);
}

.MenuItemThumbnail {
  width: 100%;
  height: 94px;
}

.MenuItem.-large .MenuItemThumbnail {
  height: 120px;
}

.MenuItem.-hasComment .MenuItemThumbnail {
  height: 160px;
}

.MenuItem__Name {
  color: var(--mono-primary);
  font-size: var(--body-normal);
  font-weight: bold;
}

.MenuItem__Price {
  color: var(--mono-secondary);
  font-size: var(--body-smaller);
}

.MenuItemModal__Image {
  width: 100%;
}

.MenuItemModal__Body {
  padding: 40px;
}

.MenuItemModal__Comment {
  display: flex;
  align-items: center;
}

.MenuItemModal__Avatar {
  width: 56px;
  height: 56px;
}

.MenuItemModal__FooterDesktop {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75px;
  background: rgba(255, 128, 128, 0.1);
  color: var(--primary);
  font-weight: bold;
  text-decoration: none;
}

.MenuItemModal__FooterMobile {
  text-decoration: none;
}

.MenuItemModal__FooterMobileButton {
  width: 100%;
}

@media (max-width: 768px) {
  .MenuItemModal__FooterMobile {
    display: flex;
    justify-content: center;
    padding: 0 16px 16px;
  }

  .MenuItemModal__Body {
    padding: 16px;
  }
}
</style>
