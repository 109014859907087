<template>
  <div>
    <common-header />
    <main>
      <common-container class="u-mb48">
        <ui-breadcrumbs :items="breadcrumbs" class="u-ml16" />
        <title-container v-if="hasNewShops" class="u-ml16 u-mt48">
          <h2>新しくスタートしたお店</h2>
        </title-container>
        <shop-list v-if="hasNewShops" wrap>
          <shop-item :shop="shop" v-for="shop in newShops" :key="shop.id" />
        </shop-list>
      </common-container>
    </main>
    <common-footer />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { shopsModule, shopsMapper } from '~/store/modules/shops';
import CommonHeader from '~/components/common/Header.vue';
import CommonFooter from '~/components/common/Footer.vue';
import CommonContainer from '~/components/common/Container.vue';
import TitleContainer from '~/components/common/TitleContainer.vue';
import UiBreadcrumbs, { BreadcrumbItem } from '~/components/ui/Breadcrumbs.vue';
import ShopList from '~/components/shop/List.vue';
import ShopItem from '~/components/shop/Item.vue';
import { createMetaInfo } from '~/util/meta';

export default Vue.extend({
  components: {
    CommonHeader,
    CommonFooter,
    CommonContainer,
    TitleContainer,
    UiBreadcrumbs,
    ShopList,
    ShopItem
  },
  head() {
    return createMetaInfo({
      title: '新しくスタートしたお店',
      pathname: '/shops/new/'
    });
  },
  computed: {
    ...shopsMapper.mapGetters(['newShops']),
    breadcrumbs(): BreadcrumbItem[] {
      const { prefecture, city } = this.$route.query;

      return [
        {
          name: 'ホーム',
          path: '/'
        },
        {
          name: '新しくスタートしたお店',
          path: '/shops/new'
        }
      ];
    },
    hasNewShops(): boolean {
      return this.newShops.length !== 0;
    }
  },
  async fetch() {
    const shopsState = shopsModule.context(this.$store);

    await shopsState.actions.listNewShops();
  }
});
</script>
