<template>
  <footer>
    <common-container>
      <div class="Footer">
        <div class="FooterLeftContainer u-mb48">
          <div class="App">
            <ui-image class="Footer__Logo" src="/img/logo-white.svg" />
            <p class="u-mt16">あのお店の「おいしい」を<br />すぐにお届け</p>
            <div class="u-mt32">
              <a href="https://apps.apple.com/jp/app/id1489433617">
                <ui-image
                  class="App__Banner u-mr16"
                  src="/img/appstore.svg"
                  alt="ChompyをApp Storeでダウンロードする"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.syn_inc.chompy&hl=ja">
                <ui-image class="App__Banner" src="/img/googleplay.svg" alt="ChompyをGoogle Playでダウンロードする" />
              </a>
            </div>
          </div>
        </div>
        <div class="FooterLink">
          <div class="FooterLink__About">
            <p class="u-mt16">
              <a href="/about/">Chompyについて</a>
            </p>
            <p class="u-mt16">
              <a href="/crew/">配達クルーになる</a>
            </p>
            <p class="u-mt16">
              <a href="https://forms.gle/9QY98swz2nwJhWKY9" target="_blank">飲食店パートナーになる</a>
            </p>
            <p class="u-mt16">
              <a href="https://help.chompy.jp/" target="_blank">ガイドライン</a>
            </p>
          </div>
          <div class="FooterLink__Policy u-mt48">
            <p class="u-mt16">
              <a href="/privacy/">プライバシーポリシー</a>
            </p>
            <p class="u-mt16">
              <a href="/tos/">ユーザー利用規約</a>
            </p>
            <p class="u-mt16">
              <a href="/tos/crew/">配達クルー利用規約</a>
            </p>
            <p class="u-mt16">
              <a href="https://chompy-inc.com/" target="_blank">運営会社</a>
            </p>
          </div>
          <div class="FooterLink__Social u-mt32">
            <a href="https://twitter.com/chompy_jp" target="_blank">
              <ui-image class="u-mr8" src="/img/twitter.svg" alt="ChompyのTwitterアカウント" />
            </a>
            <a href="https://www.instagram.com/chompy_jp_/" target="_blank">
              <ui-image class="u-mr8" src="/img/instagram.svg" alt="ChompyのInstagramアカウント" />
            </a>
            <a href="https://note.com/chompy" target="_blank">
              <ui-image class="u-mr8" src="/img/note.svg" alt="ChompyのNoteアカウント" />
            </a>
          </div>
        </div>
      </div>
    </common-container>
    <div class="Copyright">
      <common-container>
        © Chompy, Inc.
      </common-container>
    </div>
  </footer>
</template>

<script lang="ts">
import Vue from 'vue';
import CommonContainer from '~/components/common/Container.vue';
import UiImage from '~/components/ui/Image.vue';

export default Vue.extend({
  components: {
    CommonContainer,
    UiImage
  }
});
</script>

<style>
footer {
  background-color: var(--primary);
  color: var(--mono-white);
}

.Footer {
  display: flex;
  padding: 40px 0;
}

.Footer > * {
  width: 50%;
}

.FooterLeftContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Footer__Logo {
  height: 100px;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
}

.App > p {
  font-weight: bold;
  text-align: center;
}

.App__Banner {
  width: 162px;
}

.FooterLink {
  padding: 0 16px;
}

.FooterLink__About {
  display: flex;
  flex-direction: column;
}

.FooterLink__About a {
  color: var(--mono-white);
  font-size: var(--body-emphasis);
  font-weight: bold;
  text-decoration: none;
}

.FooterLink__Policy {
  display: flex;
  flex-direction: column;
}

.FooterLink__Policy a {
  color: var(--mono-white);
  font-size: var(--body-standard);
  text-decoration: none;
}

.FooterLink__Social {
  display: flex;
}

.Copyright {
  padding: 16px;
  background-color: var(--mono-primary);
  color: var(--mono-white);
  font-size: var(--body-standard);
  text-align: center;
}

@media (max-width: 768px) {
  .Footer {
    flex-direction: column;
  }

  .Footer > * {
    width: 100%;
  }

  .FooterLeftContainer {
    align-items: center;
  }

  .App__Banner {
    width: 132px;
  }

  .FooterLink__About {
    align-items: center;
  }

  .FooterLink__Policy {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .FooterLink__Social {
    display: flex;
    justify-content: center;
  }

  .Footer__Logo {
    height: 60px;
  }
}
</style>
