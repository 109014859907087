<template>
  <div>
    <common-header />
    <main class="Main">
      <div class="ConceptMovie">
        <iframe
          src="https://www.youtube.com/embed/vrN8DUYl6hU?loop=1&playlist=vrN8DUYl6hU"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
          allowfullscreen
          loading="lazy"
        />
      </div>
      <common-container class="u-mb48">
        <title-container v-if="hasFeaturedShops" class="u-ml16 u-mr16 u-mt48">
          <h2>人気のお店</h2>
          <ui-link-button class="u-forDesktop" to="/shops/featured" v-if="featuredShops.length > 5">
            すべて見る ({{ featuredShops.length }})
          </ui-link-button>
        </title-container>
        <shop-list v-if="hasFeaturedShops">
          <shop-item :shop="shop" v-for="shop in featuredShops.slice(0, 4)" :key="shop.id" />
        </shop-list>
        <div class="u-p16 u-forMobile" v-if="featuredShops.length > 5">
          <ui-link-button variant="secondary" block to="/shops/featured">
            すべて見る ({{ featuredShops.length }})
          </ui-link-button>
        </div>
        <title-container v-if="hasNewShops" class="u-ml16 u-mr16 u-mt48">
          <h2>新しくスタートしたお店</h2>
          <ui-link-button class="u-forDesktop" to="/shops/new" v-if="newShops.length > 5">
            すべて見る ({{ newShops.length }})
          </ui-link-button>
        </title-container>
        <shop-list v-if="hasNewShops">
          <shop-item :shop="shop" v-for="shop in newShops.slice(0, 4)" :key="shop.id" />
        </shop-list>
        <div class="u-p16 u-forMobile" v-if="newShops.length > 5">
          <ui-link-button variant="secondary" block to="/shops/new">すべて見る ({{ newShops.length }})</ui-link-button>
        </div>
      </common-container>
      <about-chompy />
    </main>
    <common-footer />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { shopsModule, shopsMapper } from '~/store/modules/shops';
import CommonContainer from '~/components/common/Container.vue';
import CommonHeader from '~/components/common/Header.vue';
import CommonFooter from '~/components/common/Footer.vue';
import TitleContainer from '~/components/common/TitleContainer.vue';
import UiLinkButton from '~/components/ui/LinkButton.vue';
import ShopList from '~/components/shop/List.vue';
import ShopItem from '~/components/shop/Item.vue';
import AboutChompy from '~/components/home/AboutChompy.vue';
import { createMetaInfo } from '~/util/meta';

export default Vue.extend({
  components: {
    CommonHeader,
    CommonFooter,
    UiLinkButton,
    CommonContainer,
    TitleContainer,
    ShopList,
    ShopItem,
    AboutChompy
  },
  head() {
    return createMetaInfo({});
  },
  computed: {
    ...shopsMapper.mapGetters(['featuredShops', 'newShops']),
    hasFeaturedShops(): boolean {
      return this.featuredShops.length !== 0;
    },
    hasNewShops(): boolean {
      return this.newShops.length !== 0;
    }
  },
  async fetch() {
    const shopsState = shopsModule.context(this.$store);

    await shopsState.actions.listNewShops();
    await shopsState.actions.listFeaturedShops({
      featureId: 'mdbQcA9BMg7ltZaQNNZe'
    });
  }
});
</script>

<style>
.Main {
  overflow: hidden;
}

.ConceptMovie {
  position: relative;
  width: 1140px;
  height: calc(1140px * 9 / 16);
  margin: 0 auto;
}

.ConceptMovie iframe {
  width: 100%;
  height: 100%;
}

@media (max-width: 1140px) {
  .ConceptMovie {
    width: 100%;
    height: auto;
    padding-top: 56.25%;
  }

  .ConceptMovie iframe {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
