<template>
  <transition enter-class="-enter" appear>
    <div :class="classes" role="dialog" aria-modal @keydown.esc="close">
      <div class="Modal__Backdrop" aria-hidden @click="close" />
      <div ref="modal" class="Modal__Content" tabindex="-1">
        <slot />
        <button class="Modal__Close" type="button" @click="close">
          <img src="/img/ui/modal/close.svg" alt="閉じる" />
        </button>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue';

type Constructor = VueConstructor<
  Vue & {
    $refs: {
      modal?: HTMLDivElement;
    };
  }
>;

const NAME = 'Modal';

export default (Vue as Constructor).extend({
  name: NAME,

  props: {
    close: {
      type: Function as PropType<() => void>,
      required: true
    },
    large: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes(): { [name: string]: boolean } {
      return {
        [NAME]: true,
        '-large': !!this.large
      };
    }
  },

  mounted(): void {
    this.$refs.modal?.focus();
  }
});
</script>

<style>
.Modal {
  display: flex;
  position: fixed;
  z-index: var(--z-modal);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
}

.Modal__Backdrop {
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.15s;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
}

.Modal.-enter .Modal__Backdrop {
  opacity: 0;
}

.Modal__Content {
  position: relative;
  width: 400px;
  max-width: calc(100% - 20px);
  max-height: calc(100% - 20px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  transform: scale(1);
  transition: opacity 0.15s, transform 0.15s;
  border-radius: 16px;
  outline: none;
  opacity: 1;
  background: var(--mono-white);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}

.Modal.-large .Modal__Content {
  width: 640px;
}

.Modal.-enter .Modal__Content {
  transform: scale(0.8);
  opacity: 0;
}

.Modal__Close {
  position: absolute;
  top: 16px;
  left: 16px;
  border: none;
  background: none;
  cursor: pointer;
}

.Modal__Close:hover {
  opacity: 0.7;
}
</style>
