<template>
  <div class="seeyouagain">
    <div class="seeyouagain__inner">
      <header class="MainHeader">
        <img
          src="https://storage.googleapis.com/chompy-jp-static-files/seeyouagain/user_hero.png"
          alt="ユーザーヒーロー画像"
        />
      </header>
      <main class="MainLayout">
        <div class="MainLayout__UserProfile">
          <img
            src="https://storage.googleapis.com/chompy-jp-static-files/seeyouagain/user_icon.png"
            width="40"
            height="40"
            alt="ユーザーアイコン"
          />
          <div class="MainLayout__UserInfo">
            <h2>{{ userStat?.name }}さん</h2>
            <p class="MainLayout__UserInfo__Label">{{ userStat?.user_title }}</p>
          </div>
        </div>
        <div class="MainLayout__Contents">
          <div class="MainLayout__Contents__Detail">
            <img
              src="https://storage.googleapis.com/chompy-jp-static-files/seeyouagain/face-savoring-food.png"
              width="40"
              height="40"
              alt="食べ物を食べてる"
            />
            <h3>合計注文回数</h3>
            <p class="MainLayout__Contents__Detail__Contents">{{ userStat?.completed_order_count }}<span> 回</span></p>
          </div>
          <div class="MainLayout__Contents__Detail">
            <img
              src="https://storage.googleapis.com/chompy-jp-static-files/seeyouagain/face-savoring-food2.png"
              width="40"
              height="40"
              alt="食べ物を食べてる"
            />
            <h3>利用歴</h3>
            <p class="MainLayout__Contents__Detail__Contents">{{ yearOfUse }}<span v-if="yearOfUse"> 年 </span>{{ monthOfUse }}<span> ヶ月</span></p>
          </div>
        </div>
        <div class="MainLayout__Contents" v-if="userStat?.review_count">
          <div class="MainLayout__Contents__Detail">
            <div class="MainLayout__Contents__Detail__Column">
              <img
                src="https://storage.googleapis.com/chompy-jp-static-files/seeyouagain/pencil.png"
                width="40"
                height="40"
                alt="えんぴつ"
              />
              <h3>レビュー数</h3>
              <p class="MainLayout__Contents__Detail__Contents">{{ userStat?.review_count }}<span> 件</span></p>
            </div>
            <div class="MainLayout__Contents__Detail__Column">
              <h4>うち、いいねされたレビュー数</h4>
              <p class="MainLayout__Contents__Detail__Contents">{{ userStat?.good_review_count }}<span> 件</span></p>
            </div>
          </div>
        </div>
        <div class="MainLayout__Contents">
          <div class="MainLayout__Contents__Detail">
            <div class="MainLayout__Contents__Detail__Column">
              <img
                src="https://storage.googleapis.com/chompy-jp-static-files/seeyouagain/cracker.png"
                width="40"
                height="40"
                alt="クラッカー"
              />
              <h3>注文したお店 Top5</h3>
            </div>
            <div class="MainLayout__Contents__Detail__Column">
              <ul class="MainLayout__Contents__Lists">
                <li v-for="shop in userStat?.shop_ranking" :key="shop.shop_name">
                  <div><img :src="shop.shop_icon" />
                  <p class="MainLayout__Contents__Lists__Title">{{ shop.shop_name  }}</p></div>
                  <p class="MainLayout__Contents__Lists__Label">Lv.{{  shop.completed_order_count }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="MainLayout__Annotation">
          <p>※2023年5月13日頃までの情報です</p>
        </div>
        <div class="MainLayout__LastMessage">
          <p>
            まいにちの暮らしを、おいしく笑顔に。<br />
            <br />
            いままで Chompy を<br />
            ご利用いただきありがとうございました。<br />
            <br />
            飲食店向けアプリでお会いしましょう✨️<br />
            <br />
            <a href="https://blog.chompy.jp/lp">飲食店向けアプリとは？</a>
          </p>
          <div class="MainLayout__LastMessage__Text">
            <p>SEE YOU AGAIN</p>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { userStatMapper, userStatModule } from '~/store/modules/user';
export default Vue.extend({
  computed: {
    ...userStatMapper.mapGetters(['userStat']),
    yearOfUse(): string {
      if (!this.userStat?.usage_history.match(/年/)) {
        return '';
      }
      return this.userStat?.usage_history.split('年')[0] || '';
    },
    monthOfUse(): string {
      let monthStr = this.userStat?.usage_history;
      if (this.userStat?.usage_history.match(/年/)) {
        monthStr = this.userStat?.usage_history.split('年')[1];
      }
      return monthStr?.split('ヶ月')[0] || '';
    }
  },
  async mounted() {
    const userStatState = userStatModule.context(this.$store);
    const { id } = this.$route.params;
    await userStatState.actions.getUserStat(id);
  },
});
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');
.seeyouagain {
  background-color: rgba(255, 128, 128, 0.1);
  min-height: 100vh;
}

.seeyouagain__inner {
  max-width: 560px;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  background-color: rgba(255, 128, 128, 0.1);
}

.MainHeader {
  background-color: #fff;
}

.MainLayout {
  /* Brand/Primary-UltraLight */
  background-position: right top;
  background-image: url('https://storage.googleapis.com/chompy-jp-static-files/seeyouagain/user_bg_top.png');
  background-size: 66%;
  background-repeat: no-repeat;
  border-top: 1px solid #ffe7e7;
}
.MainLayout__UserProfile {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  margin-top: 24px;
  margin-left: 20px;
}
.MainLayout__UserInfo > h2 {
  font-size: var(--fz-18);
}
.MainLayout__UserInfo__Label {
  background: #f0901d;
  border-radius: 3px;
  padding: 1px 4px;
  color: var(--mono-white);
  font-size: clamp(0.556rem, 1vw + 0.25rem, 0.667rem);
}

.MainLayout__Contents {
  margin: 20px;
  display: flex;
  gap: 16px;
}

.MainLayout__Contents__Detail {
  flex: 1;
  background-color: var(--mono-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 8px rgba(255, 110, 110, 0.1);
  border-radius: 4px;
  padding: 20px 16px 16px;
  gap: 12px;
}
.MainLayout__Contents__Detail__Column {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding-top: 8px;
}
.MainLayout__Contents__Detail > h3 {
  font-size: clamp(0.778rem, 1vw + 0.5rem, 1rem);
}
.MainLayout__Contents__Detail__Contents {
  font-size: clamp(2.222rem, 3vw + 1.5rem, 3.111rem);
  line-height: clamp(1.333rem, 3vw + 0.5rem, 2rem);
  color: var(--primary-text);
  font-weight: 700;
  margin-bottom: -4px;
}
.MainLayout__Contents__Detail p {
  font-family: 'DIN Alternate', 'Oswald', sans-serif;
}
.MainLayout__Contents__Detail p > span {
  font-size: clamp(0.889rem, 1vw + 0.5rem, 1.111rem);
  font-weight: 400;
}
.MainLayout__Contents__Detail__Column > h4 {
  font-size: clamp(0.611rem, 1vw + 0.25rem, 0.778rem);
}

.MainLayout__Contents__Lists {
  list-style-type: none;
  display: grid;
  margin: 0;
  padding: 0;
  width: 100%;
}
.MainLayout__Contents__Lists li {
  border-bottom: 1px solid var(--mono-divider);
  display: flex;
  margin: 0;
  padding: 16px 0;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
}
.MainLayout__Contents__Lists li:last-child {
  border-bottom: 0;
}
.MainLayout__Contents__Lists li img {
  flex: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.MainLayout__Contents__Lists li > div {
  display: flex;
  gap: 4px;
  align-items: center;
}

.MainLayout__Contents__Lists__Title {
  font-weight: bold;
  line-height: var(--title-level3);
  font-size: clamp(0.778rem, 2vw + 0.25rem, 0.889rem);
}
.MainLayout__Contents__Lists__Label {
  background-color: var(--mono-hint);
  border-radius: 20px;
  padding: 0 4px;
  color: var(--mono-white);
  font-weight: bold;
  font-size: clamp(0.667rem, 2vw + 0.25rem, 0.889rem);
}

.MainLayout__Annotation {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: #888888;
}

.MainLayout__LastMessage {
  margin-top: 34px;
  line-height: 140%;
  text-align: center;
  font-weight: bold;
  padding-bottom: 24px;
}

.MainLayout__LastMessage a {
  color: var(--mono-primary);
  font-weight: normal;
}

.MainLayout__LastMessage__Text {
  overflow: hidden;
  position: relative;
  height: 100px;
}
.MainLayout__LastMessage__Text p {
  font-size: clamp(3rem, 5vw + 1.5rem, 5.333rem);
  width: 700px;
  text-align: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--primary-text);
  opacity: 0.2;
}
</style>
