<template>
  <div :class="classes">
    <p class="SpeechBubble__body">
      <slot />
    </p>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

type Arrow = 'top' | 'right' | 'bottom' | 'left';

const NAME = 'SpeechBubble';

export default Vue.extend({
  name: NAME,

  props: {
    arrow: {
      type: String as PropType<Arrow>,
      default: undefined
    }
  },

  computed: {
    classes(): { [name: string]: boolean } {
      return {
        [NAME]: true,
        [`-${this.arrow}`]: !!this.arrow
      };
    }
  }
});
</script>

<style>
.SpeechBubble {
  position: relative;
  padding: 8px 16px;
  border-radius: 16px;
  background-color: var(--mono-background);
  color: var(--mono-primary);
  font-size: var(--fz-14);
  font-weight: bold;
}

.SpeechBubble__body {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.4;
}

.SpeechBubble.-top::before,
.SpeechBubble.-right::before,
.SpeechBubble.-bottom::before,
.SpeechBubble.-left::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 8px solid transparent;
}

.SpeechBubble.-top::before {
  top: -14px;
  left: calc(50% - 8px);
  border-bottom-color: var(--mono-background);
}

.SpeechBubble.-right::before {
  top: calc(50% - 8px);
  right: -14px;
  border-left-color: var(--mono-background);
}

.SpeechBubble.-bottom::before {
  bottom: -14px;
  left: calc(50% - 8px);
  border-top-color: var(--mono-background);
}

.SpeechBubble.-left::before {
  top: calc(50% - 8px);
  left: -14px;
  border-right-color: var(--mono-background);
}
</style>
