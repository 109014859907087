<template>
  <header>
    <common-container>
      <div class="Header">
        <nuxt-link to="/">
          <ui-image class="Header__Logo" src="/img/logo.svg" alt="トップページへ戻る" />
        </nuxt-link>
        <div class="HeaderLink">
          <a class="u-ml32" href="/about/">Chompyについて</a>
          <a class="u-ml32" href="/crew/">配達クルーになる</a>
          <a class="u-ml32" target="_blank" href="https://forms.gle/9QY98swz2nwJhWKY9">飲食店パートナーになる</a>
        </div>
      </div>
      <div class="CloseNotification">
        <a href="https://chompy-inc.com/news/20230413/" target="_blank">【重要】サービス終了のお知らせ</a>
      </div>
    </common-container>
  </header>
</template>

<script lang="ts">
import Vue from 'vue';
import CommonContainer from '~/components/common/Container.vue';
import UiImage from '~/components/ui/Image.vue';

export default Vue.extend({
  components: {
    CommonContainer,
    UiImage
  }
});
</script>

<style>
header {
  border-bottom: solid 1px var(--mono-divider);
  background-color: #fff;
}

.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
}

.Header__Logo {
  height: 46px;
}

.HeaderLink a {
  color: var(--mono-primary);
  font-weight: bold;
  text-decoration: none;
}

.CloseNotification a {
  display: block;
  color: #ff6e6e;
  background-color: #ffeff0;
  padding: 12px 16px;
  margin: 0px 0 12px;
  border-radius: 16px;
  text-align: center;
  font-weight: bold;
}

@media (max-width: 768px) {
  .Header {
    justify-content: center;
  }

  .Header__Logo {
    height: 38px;
  }

  .HeaderLink {
    display: none;
  }

  .CloseNotification a {
    margin-left: 8px;
    margin-right: 8px;
  }
}
</style>
