<template>
  <nav :class="classes" aria-label="パンくずリスト">
    <ol>
      <li v-for="(item, i) in filterItem" :key="i" :aria-current="i === filterItem.length - 1 ? true : false">
        <span v-if="i === filterItem.length - 1">{{ item.name }}</span>
        <nuxt-link v-else :to="item.path">
          {{ item.name }}
        </nuxt-link>
      </li>
    </ol>
    <script type="application/ld+json" v-html="jsonLd" />
  </nav>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

export interface BreadcrumbItem {
  name: string;
  path: string;
}

const BASE_URL = process.env.BASE_URL;
const NAME = 'Breadcrumbs';

export default Vue.extend({
  name: NAME,

  props: {
    items: {
      type: Array as PropType<BreadcrumbItem[]>,
      required: true
    }
  },

  computed: {
    classes(): { [name: string]: boolean } {
      return {
        [NAME]: true
      };
    },

    // https://developers.google.com/search/docs/data-types/breadcrumb
    jsonLd() {
      return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: this.items.map((item, i) => ({
          '@type': 'ListItem',
          position: i + 1,
          name: item.name,
          item: `${BASE_URL}${item.path}`
        }))
      };
    },

    filterItem() {
      return this.items.filter(item => item.name);
    }
  }
});
</script>

<style>
.Breadcrumbs {
  color: var(--mono-hint);
  font-size: var(--fz-14);
  font-weight: bold;
}

.Breadcrumbs > ol {
  padding: 0;
  list-style: none;
}

.Breadcrumbs > ol > li {
  display: inline-block;
}

.Breadcrumbs > ol > li:not(:last-child)::after {
  content: '';
  display: inline-block;
  width: 6px;
  height: 10px;
  margin: 0 8px;
  background: no-repeat center/6px 10px url('/img/ui/breadcrumbs/chevron.svg');
  vertical-align: middle;
}

.Breadcrumbs > ol > li > * {
  display: inline-block;
  vertical-align: middle;
}

.Breadcrumbs > ol > li > a {
  color: var(--mono-primary);
  text-decoration: none;
}

.Breadcrumbs > ol > li > a:hover {
  opacity: 0.7;
}
</style>
