<template>
  <section :class="classes">
    <common-container class="AboutChompy__Container">
      <div class="AboutChompy__Body">
        <h2><span>Chompyの特徴</span></h2>
        <h3>まいにちの暮らしを、おいしく笑顔に。</h3>
        <p class="AboutChompy__Description">
          Chompy(チョンピー)は、まいにちの暮らしを豊かにするために生まれた、国内発の新しいフードデリバリーサービスです。<br />
          より多くの人に、街の「おいしい」をお届けするため、日常に寄り添うお得な仕組みをつくっています。<br />
          笑って食べられるまいにちの暮らしに「Chompy」を。<br />
        </p>
        <div class="u-forDesktop">
          <div class="AboutChompy__Banner">
            <ui-image src="/img/app-icon.svg" width="128" height="128" lazy />
            <div class="AboutChompy__BannerBody">
              <p class="u-fz18 u-fwB u-mb8">
                あのお店の「おいしい」をすぐにお届け<br />
                <span class="u-fz24">Chompy (チョンピー)</span>
              </p>
              <p class="u-fz18 u-fwB">アプリからご注文いただけます♪</p>
            </div>
          </div>
          <a href="https://apps.apple.com/jp/app/id1489433617" target="_blank" rel="noopener noreferrer">
            <ui-image
              class="u-mr16"
              src="/img/appstore.svg"
              width="162"
              height="54"
              alt="App Storeからダウンロード"
              lazy
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.syn_inc.chompy&hl=ja"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ui-image src="/img/googleplay.svg" width="162" height="54" alt="Google Playで手に入れよう" lazy />
          </a>
        </div>
      </div>
      <div class="AboutChompy__Figure">
        <vue-agile :options="agileOptions">
          <div>
            <ui-image src="/img/home/figure_01.png" loading="eager" />
          </div>
          <div>
            <ui-image src="/img/home/figure_02.png" lazy />
          </div>
          <div>
            <ui-image src="/img/home/figure_03.png" lazy />
          </div>
          <div>
            <ui-image src="/img/home/figure_04.png" lazy />
          </div>
        </vue-agile>
      </div>
    </common-container>
  </section>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { VueAgile } from 'vue-agile';
import UiImage from '~/components/ui/Image.vue';
import CommonContainer from '~/components/common/Container.vue';

const NAME = 'AboutChompy';

export default Vue.extend({
  name: NAME,

  components: {
    VueAgile,
    UiImage,
    CommonContainer
  },

  data() {
    return {
      agileOptions: {
        autoplay: true,
        dots: false,
        navButtons: false,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              unagile: true
            }
          }
        ]
      }
    };
  },

  computed: {
    classes(): { [name: string]: boolean } {
      return {
        [NAME]: true
      };
    }
  }
});
</script>

<style>
.AboutChompy {
  position: relative;
  z-index: -1;
  /* stylelint-disable-next-line declaration-colon-newline-after */
  background: linear-gradient(
    to bottom right,
    var(--mono-white) 0%,
    var(--mono-white) 50%,
    rgba(255, 128, 128, 0.1) 50%,
    rgba(255, 128, 128, 0.1) 100%
  );
}

.AboutChompy__Container {
  display: flex;
  padding: 72px 20px;
}

.AboutChompy__Body {
  flex: 1;
  margin-right: 64px;
}

.AboutChompy h2 {
  margin-bottom: 120px;
  color: var(--primary-text);
  font-size: var(--title-level1);
}

.AboutChompy h2 > span {
  display: inline-block;
}

.AboutChompy h2 > span::after {
  content: '';
  display: block;
  height: 4px;
  margin-top: 8px;
  border-radius: 2px;
  background-color: var(--primary-text);
}

.AboutChompy h3 {
  margin-bottom: 24px;
  font-size: var(--title-level1);
}

.AboutChompy__Description {
  margin-bottom: 120px;
  font-size: var(--body-standard);
  line-height: 2;
}

.AboutChompy__Banner {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.AboutChompy__BannerBody {
  flex: 1;
  margin-left: 24px;
}

.AboutChompy__Figure .agile__slide + .agile__slide {
  display: none;
}

.AboutChompy__Figure img {
  width: 364px;
  height: 738px;
}

@media (max-width: 768px) {
  .AboutChompy {
    background: rgba(255, 128, 128, 0.1);
  }

  .AboutChompy__Container {
    display: block;
    flex-direction: column;
    padding: 40px 20px 0;
  }

  .AboutChompy__Body {
    margin-right: 0;
  }

  .AboutChompy h2 {
    margin-bottom: 32px;
    text-align: center;
  }

  .AboutChompy__Description {
    margin-bottom: 32px;
  }

  .AboutChompy__Figure {
    width: 80%;
    margin: 0 auto -35%;
  }

  .AboutChompy__Figure .agile__list {
    overflow: visible;
  }

  .AboutChompy__Figure .agile__slide {
    display: block;
    padding: 0 8px;
  }

  .AboutChompy__Figure .agile__slide + .agile__slide {
    display: block;
  }

  .AboutChompy__Figure img {
    width: 100%;
    height: auto;
  }
}
</style>
