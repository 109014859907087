<template>
  <div>
    <common-header />
    <main>
      <common-container class="u-mb48">
        <ui-breadcrumbs :items="breadcrumbs" class="u-ml16" />
        <div class="HeroContainer">
          <div class="Hero" aria-role="image" :style="`background-image: url(${shop.image})`"></div>
        </div>
        <div class="ItemDetailLayout">
          <div class="ItemDetailLayout__InfoWrap">
            <div class="ItemDetailLayout__Meta">
              <h1 class="u-mt48">{{ shop.name }}</h1>
              <p class="u-mt16">平均価格(1人) {{ shop.average_price_per_serving }}</p>
              <div v-if="menuCategories.length > 0" class="u-mt16" style="display: flex;">
                <div style="padding: 8px 16px 8px 0; border-right: solid 1px var(--mono-divider);">
                  <p>お届け時間</p>
                  <p style="color: var(--mono-primary); font-size: var(--body-emphasis); font-weight: bold;">
                    {{ toDeliveryMinutes(shop.average_cooking_minutes) }}分
                  </p>
                </div>
                <div style="padding: 8px 16px;">
                  <p>配達手数料</p>
                  <p style="color: var(--mono-primary); font-size: var(--body-emphasis); font-weight: bold;">
                    ¥{{ shop.delivery_fee }}〜
                  </p>
                </div>
              </div>
            </div>
            <div class="ItemDetailLayout__Basic">
              <div class="BasicInformation u-mt48">
                <h2>基本情報</h2>
                <ui-image rounded :src="shop.image" class="u-mt16" />
                <ui-speech-bubble class="u-mt32 u-taC" arrow="bottom">{{ shop.owner_comment }}</ui-speech-bubble>
                <ui-image circle fit="cover" :src="shop.icon" class="BasicInformation__Avatar u-mt16" />
                <div class="BasicInformationTable u-mt32">
                  <div style="display: flex;">
                    <ui-image class="u-mr8" src="/img/icon/location.svg" />
                    <div>
                      <p>{{ shop.postal_code }}</p>
                      <p>{{ shop.prefecture }} {{ shop.address1 }} {{ shop.address2 }}</p>
                    </div>
                  </div>
                  <div v-if="shop.business_hour_text" style="display: flex;">
                    <ui-image class="u-mr8" src="/img/icon/time.svg" />
                    <p>{{ shop.business_hour_text }}</p>
                  </div>
                  <div v-if="shop.official_site" style="display: flex;">
                    <ui-image class="u-mr8" src="/img/icon/web.svg" />
                    <p>
                      <a :href="shop.official_site">{{ shop.official_site }}</a>
                    </p>
                  </div>
                  <div v-if="shop.instagram || shop.twitter || shop.youtube || shop.facebook">
                    <p>
                      <a v-if="shop.instagram" :href="shop.instagram" target="_blank">
                        <ui-image src="/img/icon/instagram.svg" alt="Instagram" style="width: 32px; height: 32px;" />
                      </a>
                      <a v-if="shop.twitter" class="u-ml16" :href="shop.twitter" target="_blank">
                        <ui-image src="/img/icon/twitter.svg" alt="Twitter" style="width: 32px; height: 32px;" />
                      </a>
                      <a v-if="shop.youtube" class="u-ml16" :href="shop.youtube" target="_blank">
                        <ui-image src="/img/icon/youtube.svg" alt="YouTube" style="width: 32px; height: 32px;" />
                      </a>
                      <a v-if="shop.facebook" class="u-ml16" :href="shop.facebook" target="_blank">
                        <ui-image src="/img/icon/facebook.svg" alt="Facebook" style="width: 32px; height: 32px;" />
                      </a>
                    </p>
                  </div>
                  <div v-if="shop.description">
                    <p>{{ shop.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <client-only>
            <div class="ItemDetailLayout__Content">
              <div v-for="menuItemSection in menuItemSections" :key="menuItemSection.id">
                <h2 class="u-mt48">{{ menuItemSection.name }}</h2>
                <menu-list :column="3">
                  <menu-item
                    v-for="menuItem in menuItemSection.menu_items"
                    :shop="shop"
                    :menu-item="menuItem"
                    :has-comment="menuItemSection.style === 'SHOP_COMMENT'"
                    :key="menuItem.id"
                    large
                  />
                </menu-list>
              </div>
              <div>
                <h2 class="u-mt48">カテゴリーから探す</h2>
                <div v-for="menuCategory in menuCategories" :key="menuCategory.id">
                  <h3 class="u-mt16">{{ menuCategory.name }}</h3>
                  <menu-list :column="4">
                    <menu-item
                      v-for="menuItem in menuCategory.menu_items"
                      :shop="shop"
                      :menu-item="menuItem"
                      :key="menuItem.id"
                    />
                  </menu-list>
                </div>
              </div>
            </div>
          </client-only>
        </div>
      </common-container>
    </main>
    <common-footer />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { shopModule, shopMapper } from '~/store/modules/shop';
import CommonHeader from '~/components/common/Header.vue';
import CommonFooter from '~/components/common/Footer.vue';
import CommonContainer from '~/components/common/Container.vue';
import UiImage from '~/components/ui/Image.vue';
import UiBreadcrumbs, { BreadcrumbItem } from '~/components/ui/Breadcrumbs.vue';
import UiSpeechBubble from '~/components/ui/SpeechBubble.vue';
import MenuList from '~/components/menu/List.vue';
import MenuItem from '~/components/menu/Item.vue';
import { MenuItemSection, MenuCategory } from '~/types/api/shop';
import { toDate, toDeliveryMinutes } from '~/util/date';
import { createMetaInfo } from '~/util/meta';
import { CITY_MAP } from '~/constants/city';

export default Vue.extend({
  components: {
    CommonHeader,
    CommonFooter,
    CommonContainer,
    UiImage,
    UiBreadcrumbs,
    UiSpeechBubble,
    MenuList,
    MenuItem
  },
  computed: {
    ...shopMapper.mapGetters(['shop', 'menus', 'menuItemSections', 'menuCategories']),
    breadcrumbs(): BreadcrumbItem[] {
      const city = CITY_MAP.find(item => this.shop?.address1?.includes(item)) ?? '';
      return [
        {
          name: 'ホーム',
          path: '/'
        },
        {
          name: city,
          path: `/shops?prefecture=東京都&city=${city}`
        },
        {
          name: this.shop?.name ?? '',
          path: `/shops/${this.$route.params.shopId}`
        }
      ];
    }
  },
  methods: {
    toDeliveryMinutes
  },
  head() {
    if (this.shop.twitter) {
      return createMetaInfo({
        title: this.shop.name,
        pathname: `/shops/${this.shop.id}`,
        imageUrl: this.shop.image,
        twitterCreator: this.shop.twitter
      });
    }

    return createMetaInfo({
      title: this.shop.name,
      pathname: `/shops/${this.shop.id}`,
      imageUrl: this.shop.image
    });
  },
  fetch({ store, route }) {
    const { shopId } = route.params;
    const shopState = shopModule.context(store);
    if (shopId !== 'undefined') {
      return shopState.actions.getShop(shopId);
    }
  },
  async mounted() {
    const shopState = shopModule.context(this.$store);
    const { shopId } = this.$route.params;

    await shopState.actions.getShop(shopId);
    await shopState.actions.listMenus(shopId);

    const menu = shopState.getters.menus.find(menu => {
      const now = new Date();
      const start = toDate(menu.startTime);
      const end = toDate(menu.endTime);

      return true; // start <= now && now < end;
    });

    if (menu?.id) {
      await shopState.actions.listMenuItemSections({
        shopId,
        menuId: menu.id
      });

      await shopState.actions.listMenuCategories({
        shopId,
        menuId: menu.id
      });
    }
  }
});
</script>

<style>
.HeroContainer {
  position: relative;
  height: 0;
  padding-top: 50%;
  overflow: hidden;
}

.Hero {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
}

.ItemDetailLayout {
  display: grid;
  grid-template-columns: 360px auto;
  grid-template-rows: max-content auto;
}

.ItemDetailLayout__InfoWrap {
  grid-column: 1;
  grid-row: 1;
}

.ItemDetailLayout__Meta {
  /* grid-column: 1;
  grid-row: 1; */
  padding: 0 16px;
}

.ItemDetailLayout__Meta p {
  color: var(--mono-secondary);
}

.ItemDetailLayout__Basic {
  /* grid-column: 1;
  grid-row: 2; */
  padding: 0 16px;
}

.BasicInformation {
  display: flex;
  flex-direction: column;
}

.BasicInformation__Avatar {
  align-self: center;
  width: 96px;
  height: 96px;
}

.BasicInformationTable > div {
  padding: 16px 0;
  border-top: solid 1px var(--mono-divider);
  overflow-wrap: break-word;
}

.ItemDetailLayout__Content {
  grid-column: 2;
  grid-row-end: 3;
  grid-row-start: 1;
  padding-left: 12px;
}

@media (max-width: 1140px) {
  .ItemDetailLayout {
    display: flex;
    flex-direction: column;
  }
  
  .ItemDetailLayout__InfoWrap {
    display: contents;
  }

  .ItemDetailLayout__Meta {
    order: 1;
  }

  .ItemDetailLayout__Basic {
    order: 3;
  }

  .ItemDetailLayout__Content {
    order: 2;
    padding: 0 12px;
  }
}
</style>
