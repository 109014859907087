<template>
  <nuxt-link class="ShopItem" :to="`/shops/${shop.id}`">
    <div class="ShopOwner">
      <ui-image class="ShopOwner__Avatar" circle fit="cover" :src="shop.icon" :alt="shop.ownerName" />
      <ui-speech-bubble class="u-ml8" arrow="left">{{ shop.owner_comment }}</ui-speech-bubble>
    </div>
    <ui-image class="ShopThumbnail u-mt8" rounded fit="cover" :src="shop.image" :alt="shop.name" />
    <div class="ShopInformation u-mt8">
      <div>
        <h2>{{ shop.name }}</h2>
        <p>平均価格(1人) {{ shop.average_price_per_serving }}</p>
      </div>
      <span>
        <strong>{{ shop.status_badge }}</strong>
      </span>
    </div>
  </nuxt-link>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import UiImage from '~/components/ui/Image.vue';
import UiSpeechBubble from '~/components/ui/SpeechBubble.vue';
import { Shop } from '~/types/api/shop';
import { toDeliveryMinutes } from '~/util/date';

export default Vue.extend({
  components: {
    UiImage,
    UiSpeechBubble
  },
  props: {
    shop: {
      type: Object as () => PropType<Shop>,
      required: true
    }
  },
  methods: {
    toDeliveryMinutes
  }
});
</script>

<style>
.ShopItem {
  display: flex;
  flex-direction: column;
  width: 32%;
  min-width: 256px;
  text-decoration: none;
}

.ShopOwner {
  display: flex;
  align-items: stretch;
}

.ShopOwner__Avatar {
  width: 56px;
  height: 56px;
}

.ShopThumbnail {
  width: 100%;
  height: 18vw; /* 32% * 0.5625 */
  min-height: 144px; /* 256px * 0.5625 */
}

.ShopInformation {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.ShopInformation h2 {
  color: var(--mono-primary);
  font-size: var(--title-level3);
}

.ShopInformation p {
  color: var(--mono-secondary);
  font-size: var(--body-smaller);
}

.ShopInformation span {
  min-width: 100px;
  padding: 2px 8px;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.06);
  color: var(--mono-primary);
  font-size: var(--body-smaller);
}

@media (max-width: 768px) {
  .ShopList.-wrap .ShopItem {
    width: 100%;
  }

  .ShopList.-wrap .ShopThumbnail {
    height: 56.25vw;
  }
}
</style>
