<template>
  <nuxt-link :class="classes" :to="to" :prefetch="prefetch">
    <slot />
  </nuxt-link>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { ButtonVariant } from '~/components/ui/Button.vue';

const NAME = 'LinkButton';

export default Vue.extend({
  name: NAME,

  props: {
    to: {
      type: String,
      required: true
    },

    prefetch: {
      type: Boolean,
      default: true
    },

    variant: {
      type: String as PropType<ButtonVariant>,
      default: undefined
    },

    block: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes(): { [name: string]: boolean } {
      return {
        [NAME]: true,
        [`-${this.variant}`]: !!this.variant,
        '-block': !!this.block
      };
    }
  }
});
</script>

<style>
.LinkButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  padding: 0 20px;
  border: 1px solid var(--mono-divider);
  border-radius: 4px;
  background: var(--mono-white);
  color: var(--mono-primary);
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
}

.LinkButton:hover {
  opacity: 0.7;
}

.LinkButton.-primary {
  border-radius: 100rem;
  border-color: var(--primary);
  background: var(--primary);
  color: var(--mono-white);
}

.LinkButton.-secondary {
  border-radius: 100rem;
  border-color: var(--mono-background);
  background: var(--mono-background);
}

.LinkButton.-block {
  display: flex;
  width: 100%;
}
</style>
